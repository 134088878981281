import Login from "../../container/Login";
import RoutePath from "./RoutePath";
import Home from '../../container/Home'
import Customer from "../../container/Customer";
import KioskCustomer from "../../container/KioskCustomer";
import Verification from "../../container/verification";
import KioskVerification from '../../container/KioskVerification';
import Welcome from "../../container/welcome";
import StoreManager from "../../container/StoreManager";
import Feedback from "../../container/Feedback";
import KioskFeedback from "../../container/KioskFeedback";
import AddAgent from "../../container/AddAgent";
import AgentList from "../../container/AgentList";
import AdminSubscriptionList from "../../container/AdminSubscriptionList";
import AdminAddSubscription from "../../container/AdminAddSubscription";
import AdminViewSubscription from "../../container/AdminViewSubscription";
import AdminAddOnList from "../../container/AdminAddOnList";
import AdminAddAddOn from "../../container/AdminAddAddOn "
import AdminViewAddOn from "../../container/AdminViewAddOn ";
import VendorList from "../../container/VendorList";
import DashboardScreen from "../../container/DashboardScreen";
import AddVendorAgent from '../../container/AddVendorAgent'
import ViewVendor from "../../container/ViewVendor";
import ViewAgent from "../../container/ViewAgent";
import ForgetPassword from "../../component/ForgetPassword";
import VendorWebhook from "../../container/VendorWeebhook";
import AccessDenied from "../../component/AccessDenied";
import PageNotFound from "../../component/PageNotFound";
import Product3dRendering from "../../container/3dRendering";
import SetPassword from "../../component/SetPassword";
import ModalViewer from "../../container/modalViewer";
import VendorARProductsList from "../../container/VendorARProductsList";
import AddVendorARProduct from "../../container/AddVendorARProduct";
import VendorViewARProduct from "../../container/VendorViewARProduct";
import ViewVendorProfile from "../../container/ViewVendorProfile";
import UpdateVendorProfile from "../../container/UpdateVendorProfile";
import AdminARProductsList from "../../container/AdminARProductsList";
import AdminViewARProduct from "../../container/AdminViewARProduct";
import VendorVBList from "../../container/VendorVBList";
import AddVendorVBImage from "../../container/AddVendorVBImage";
import VendorViewVBImage from "../../container/VendorViewVBImage";
import KioskGeneralCustomerFeedback from "../../container/KioskGeneralCustomerFeedback";
import VendorFeedbackList from "../../container/VendorFeedbackList";
import Srm_ip_customer from "../../container/Srm_ip_customer";
import VendorRoom from "../../container/VendorRoom";
import VendorRoomView from "../../container/VendorRoomView";
import VendorRoomAdd from "../../container/VendorRoomAdd";
import VendorRoomServiceList from "../../container/VendorRoomServiceList";
import VendorServiceList from "../../container/VendorServiceList";
import AddVendorService from "../../container/AddVendorService";
import VendorViewService from "../../container/VendorViewService";
import VendorViewRoomService from "../../container/VendorViewRoomService";
import AddVendorRoomService from "../../container/AddVendorRoomService";
const ROUTES = [
    // Start Login
    {
        screenName: 'Login',
        path: RoutePath.LOGIN_SCREEN_PATH,
        component: Login,
        exact: true,
        isAuthenticated: false,
    },
    {
        screenName: 'AdminLogin',
        path: RoutePath.ADMIN_LOGIN_SCREEN_PATH,
        component: Login,
        exact: true,
        isAuthenticated: false,
    },
    {
        screenName: 'VendorLogin',
        path: RoutePath.VENDOR_LOGIN_SCREEN_PATH,
        component: Login,
        exact: true,
        isAuthenticated: false,
    },
    // End Login
    // {
    //     screenName: 'Home',
    //     path: RoutePath.HOME_SCREEN_PATH,
    //     component: Home,
    //     exact: true,
    //     isAuthenticated: false,
    // },
    // {
    //     screenName: 'Welcome',
    //     path: RoutePath.WELCOME_SCREEN_PATH,
    //     component: Welcome,
    //     exact: true,
    //     isAuthenticated: false,
    // },
    // Customer --> Call Initiate
    {
        screenName: 'Customer',
        path: RoutePath.CUSTOMER_SCREEN_PATH,
        component: Customer,
        exact: true,
        isAuthenticated: false,
    },
    {
        screenName: 'KioskCustomer',
        path: RoutePath.KIOSK_SCREEN_PATH,
        component: KioskCustomer,
        exact: true,
        isAuthenticated: false,
    },
    // Customer --> FeedBack
    {
        screenName: 'Feedback',
        path: RoutePath.FEEDBACK_SCREEN_PATH,
        component: Feedback,
        exact: true,
        isAuthenticated: false,
    },
    {
        screenName: 'KioskFeedback',
        path: RoutePath.KIOSK_FEEDBACK_SCREEN_PATH,
        component: KioskFeedback,
        exact: true,
        isAuthenticated: false,
    },
    {
        screenName: 'KioskGeneralCustomerFeedback',
        path: RoutePath.KIOSK_GENERAL_CUSTOMER_FEEDBACK_SCREEN_PATH,
        component: KioskGeneralCustomerFeedback,
        exact: true,
        isAuthenticated: false,
    },
    // Customer --> Call Screen
    {
        screenName: 'CustomerVerification',
        path: RoutePath.CUSTOMER_VERIFICATION_SCREEN_PATH,
        component: () => <Verification isCustomer={true} />,
        exact: true,
        isAuthenticated: false,
    },
    {
        screenName: 'CustomerVerification',
        path: RoutePath.SRM_HEAD_VERIFICATION_SCREEN_PATH,
        component: () => <Verification isCustomer={true} />,
        exact: true,
        isAuthenticated: false,
    },
    {
        screenName: 'KioskCustomerVerification',
        path: RoutePath.KIOSK_CUSTOMER_VERIFICATION_SCREEN_PATH,
        component: () => <KioskVerification isCustomer={true} />,
        exact: true,
        isAuthenticated: false,
    },
    {
        screenName: 'Verification',
        path: RoutePath.VERIFICATION_SCREEN_PATH,
        component: Verification,
        exact: true,
        isAuthenticated: false,
    },
    // SRM IP
    {
        screenName: 'SRM IP Customer',
        path: RoutePath.SRM_IP_CUSTOMER_SCREEN_PATH,
        component: Srm_ip_customer,
        exact: true,
        isAuthenticated: false,
    },
    // Agent --> CallQue
    {
        screenName: 'Store',
        path: RoutePath.STOREMANAGER_SCREEN_PATH,
        component: StoreManager,
        exact: true,
        isAuthenticated: false,
    },
    // Agent --> Call Screen
    {
        screenName: 'AgentVerification',
        path: RoutePath.AGENT_VERIFICATION_SCREEN_PATH,
        isCustomer: false,
        component: () => <Verification isCustomer={false} />,
        exact: true,
        isAuthenticated: false,
    },
    {
        screenName: 'KioskAgentVerification',
        path: RoutePath.KIOSK_AGENT_VERIFICATION_SCREEN_PATH,
        isCustomer: false,
        component: () => <KioskVerification isCustomer={false} />,
        exact: true,
        isAuthenticated: false,
    },
    //Admin --> Dasboard
    {
        screenName: "AdminDashboard",
        path: RoutePath.ADMIN_DASHBOARD_SCREEN,
        component: DashboardScreen,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Vendor list
    {
        screenName: 'AdminVendorList',
        path: RoutePath.ADMIN_VENDOR_LIST,
        component: VendorList,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Vendor Create
    {
        screenName: 'AddAdminVendor',
        path: RoutePath.ADD_ADMIN_VENDOR,
        component: AddVendorAgent,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Vendor Update
    {
        screenName: 'UpdateAdminVendor',
        path: RoutePath.ADMIN_VENDOR_UPDATE,
        component: AddVendorAgent,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Vendor View
    {
        screenName: 'ViewVendor',
        path: RoutePath.VIEW_ADMIN_VENDOR,
        component: ViewVendor,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Agent List
    {
        screenName: 'AdminAgentList',
        path: RoutePath.ADMIN_AGENT_LIST,
        component: AgentList,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Agent Create
    {
        screenName: 'AdminAddAgent',
        path: RoutePath.ADMIN_ADD_AGENT_LIST,
        component: AddAgent,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Agent Update
    {
        screenName: 'AdminUpdateAgent',
        path: RoutePath.ADMIN_UPDATE_AGENT_LIST,
        component: AddAgent,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Agent View
    {
        screenName: 'ViewAgent',
        path: RoutePath.ADMIN_AGENT_VIEW,
        component: ViewAgent,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Subscription List
    {
        screenName: 'SubscriptionList',
        path: RoutePath.SUBSCRIPTION_LIST,
        component: AdminSubscriptionList,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Subscription Create
    {
        screenName: 'AddSubscription',
        path: RoutePath.ADD_SUBSCRIPTION,
        component: AdminAddSubscription,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Subscription Update
    {
        screenName: 'UpdateSubscription',
        path: RoutePath.UPDATE_SUBSCRIPTION,
        component: AdminAddSubscription,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Subscription View
    {
        screenName: 'ViewSubscription',
        path: RoutePath.VIEW_SUBSCRIPTION,
        component: AdminViewSubscription,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Addon List
    {
        screenName: 'AddonList',
        path: RoutePath.ADDON_LIST,
        component: AdminAddOnList,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Addon Create
    {
        screenName: 'AddAddOn',
        path: RoutePath.ADD_ADDON,
        component: AdminAddAddOn,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Addon Update
    {
        screenName: 'UpdateAddOn',
        path: RoutePath.UPDATE_ADDON,
        component: AdminAddAddOn,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> Addon View
    {
        screenName: 'ViewAddOn',
        path: RoutePath.VIEW_ADDON,
        component: AdminViewAddOn,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> AR Product List
    {
        screenName: 'AdminARProductList',
        path: RoutePath.ADMIN_AR_PRODUCTS,
        component: AdminARProductsList,
        exact: true,
        isAuthenticated: false
    },
    // Admin --> AR product View
    {
        screenName: 'ViewAdminProdcutDetails',
        path: RoutePath.ADMIN_AR_PRODUCT_VIEW,
        component: AdminViewARProduct,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> Dasboard
    {
        screenName: "VendorDashboard",
        path: RoutePath.VENDOR_DASHBOARD_SCREEN,
        component: DashboardScreen,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> Agent List
    {
        screenName: 'VendorAgentList',
        path: RoutePath.VENDOR_AGENT_LIST,
        component: AgentList,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> Agent Create
    {
        screenName: 'VendorAddAgent',
        path: RoutePath.VENDOR_ADD_AGENT_LIST,
        component: AddAgent,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> Agent update
    {
        screenName: 'VendorUpdateAgent',
        path: RoutePath.VENDOR_UPDATE_AGENT_LIST,
        component: AddAgent,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> Agent View
    {
        screenName: 'VendorViewAgent',
        path: RoutePath.VENDOR_AGENT_VIEW,
        component: ViewAgent,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> Addon List
    {
        screenName: 'VendorAddonList',
        path: RoutePath.VENDOR_ADDON_LIST,
        component: AdminAddOnList,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> Addon View
    {
        screenName: 'ViewAddOn',
        path: RoutePath.VENDOR_VIEW_ADDON,
        component: AdminViewAddOn,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> AR product list
    {
        screenName: 'VendorARProductList',
        path: RoutePath.VENDOR_AR_PRODUCTS,
        component: VendorARProductsList,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> AR product Create
    {
        screenName: 'AddVendorARProduct',
        path: RoutePath.VENDOR_AR_PRODUCTS_ADD,
        component: AddVendorARProduct,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> AR product Update
    {
        screenName: 'UpdateProduct',
        path: RoutePath.VENDOR_AR_PRODUCT_EDIT,
        component: AddVendorARProduct,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> AR product View
    {
        screenName: 'ViewProdcutDetails',
        path: RoutePath.VENDOR_AR_PRODUCT_VIEW,
        component: VendorViewARProduct,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> VB Image list
    {
        screenName: 'VendorVBList',
        path: RoutePath.VENDOR_VB_LIST,
        component: VendorVBList,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> VB Image Create
    {
        screenName: 'VendorVBAdd',
        path: RoutePath.VENDOR_VB_ADD,
        component: AddVendorVBImage,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> VB Image Update
    {
        screenName: 'VendorVBUpdate',
        path: RoutePath.VENDOR_VB_EDIT,
        component: AddVendorVBImage,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> VB Image View
    {
        screenName: 'VendorVBView',
        path: RoutePath.VENDOR_VB_VIEW,
        component: VendorViewVBImage,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> Profile Update
    {
        screenName: 'UpdateVendorProfile',
        path: RoutePath.VENDOR_PROFILE_UPDATE,
        component: UpdateVendorProfile,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> Profile Details
    {
        screenName: 'ViewVendorProfile',
        path: RoutePath.VENDOR_PROFILE_DETAILS,
        component: ViewVendorProfile,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> Webhook
    {
        screenName: 'VedorWeebhookConfig',
        path: RoutePath.VENDOR_WEBHOOK_CONFIG,
        component: VendorWebhook,
        exact: true,
        isAuthenticated: false
    },
    // Vendor --> Feedback
    {
        screenName: 'VendorFeedbackList',
        path: RoutePath.VENDOR_FEEDBACK,
        component: VendorFeedbackList,
        exact: true,
        isAuthenticated: false
    },
    // common --> Forget password
    {
        screenName: 'forgetPassowrd',
        path: RoutePath.FORGET_PASSWORD,
        component: ForgetPassword,
        exact: true,
        isAuthenticated: false
    },
    // common --> Access Denide
    {
        screenName: 'accessDenied',
        path: RoutePath.ACCESS_DENIED,
        component: AccessDenied,
        exact: true,
        isAuthenticated: false
    },
    // common --> Set password
    {
        screenName: 'setpassword',
        path: RoutePath.SET_PASSWORD,
        component: SetPassword,
        exact: true,
        isAuthenticated: false
    },
    // common --> Page not found
    {
        screenName: 'pageNotFound',
        path: RoutePath.PAGE_NOT_FOUND,
        component: PageNotFound,
        exact: true,
        isAuthenticated: false
    },
    // common --> 3d Rendering
    {
        screenName: 'productRendering',
        path: RoutePath.AR_PRODUCT_RENDER,
        component: Product3dRendering,
        exact: true,
        isAuthenticated: false
    },
    // common --> 3d Model rendering
    {
        screenName: 'productRendering',
        path: RoutePath.AR_PRODUCT_MODEL,
        component: ModalViewer,
        exact: true,
        isAuthenticated: false
    },
    //common --> vendor services
    {
        screenName: 'vendorServices',
        path: RoutePath.VENDOR_SERVICE,
        component: VendorServiceList,
        exact: true,
        isAuthenticated: false
    },
    //common --> vendor service add
    {
        screenName: 'vendorServices',
        path: RoutePath.VENDOR_SERVICE_ADD,
        component: AddVendorService,
        exact: true,
        isAuthenticated: false
    },
     //common --> vendor service edit
     {
        screenName: 'vendorServices',
        path: RoutePath.VENDOR_SERVICE_EDIT,
        component: AddVendorService,
        exact: true,
        isAuthenticated: false
    },

     //common --> vendor service view
     {
        screenName: 'vendorServices',
        path: RoutePath.VENDOR_SERVICE_VIEW,
        component: VendorViewService,
        exact: true,
        isAuthenticated: false
    } ,
    //common --> vendor room
    {
        screenName: 'vendorRoom',
        path: RoutePath.VENDOR_ROOM,
        component: VendorRoom,
        exact: true,
        isAuthenticated: false
    },
    //common --> vendor room view
    {
        screenName: 'vendorRoomView',
        path: RoutePath.VENDOR_ROOM_VIEW,
        component: VendorRoomView,
        exact: true,
        isAuthenticated: false
    },
    //common --> vendor room add
    {
        screenName: 'vendorRoomAdd',
        path: RoutePath.VENDOR_ROOM_ADD,
        component: VendorRoomAdd,
        exact: true,
        isAuthenticated: false
    },
     //common --> vendor room edit
     {
        screenName: 'vendorRoomEdit',
        path: RoutePath.VENDOR_ROOM_EDIT,
        component: VendorRoomAdd,
     },

     //common --> vendor room service 
    {
        screenName: 'vendorRoomService',
        path: RoutePath.VENDOR_ROOM_SERVICE,
        component: VendorRoomServiceList,
        exact: true,
        isAuthenticated: false
    },
     //common --> vendor room service add
    {
        screenName: 'vendorRoomService',
        path: RoutePath.VENDOR_ROOM_SERVICE_ADD,
        component: AddVendorRoomService,
        exact: true,
        isAuthenticated: false
    
    },
    // {
    //     screenName: 'vendorRoomService',
    //     path: RoutePath.VENDOR_ROOM_SERVICE_EDIT,
    //     component: AddVendorRoomService,
    //     exact: true,
    //     isAuthenticated: false
    
    // },
     //common --> vendor room service view
    {
        screenName: 'vendorRoomService',
        path: RoutePath.VENDOR_ROOM_SERVICE_VIEW,
        component: VendorViewRoomService,
        exact: true,
        isAuthenticated: false
    }
]

export default ROUTES