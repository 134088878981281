import { combineReducers } from "redux";
import { LoginReducer } from "./LoginReducer";
import { CustomerListReducer } from "./CustomerListReducer";
import { CustomerDeclineReducer } from "./CustomerDeclineReducer";
import { CustomerCallListReducer } from './CustomerCallListReducer';
import { UpdateAppointmentReducer } from './UpdateAppointmentReducer';
import { feedBackReducer } from './FeedBackReducer'
import { chatListReducer } from "./chatListReducer";
import { callHistoryReducer } from './CallHistoryReducer'
import { agentDetailsReducer } from './AgentDetailsReducer'
import { SubscriptionReducer } from "./SubscriptionReducer";
import { adminDashboardReducer } from "./DashboardReducer";
import { vendorListReducer } from './VendorListReducer'
import { AddOnReducer } from './AddOnReducer '
import { ARProductReducer } from './ARProductReducer'
import { VendorProfileReducer } from './VendorProfileReducer'
import { RenewSubscriptionReducer } from './RenewSubscriptionReducer'
import { AgeVerificationReducer } from "./AgeVerificationReducer";
import { VBImageReducer } from "./VBImageReducer";
import { VendorRoomReducer } from "./VendorRoomReducer";
import { vendorRoomServiceReducer } from "./VendorRoomServiceReducer";
export const rootReducer =
  combineReducers({
    LOGIN_CREDS: LoginReducer,
    CUSTOMER_LIST: CustomerListReducer,
    CUSTOMER_DECLINE: CustomerDeclineReducer,
    CUSTOMER_CALL_LIST: CustomerCallListReducer,
    UPDATE_APPOINTMENT: UpdateAppointmentReducer,
    FEEDBACK: feedBackReducer,
    CHAT_LIST: chatListReducer,
    CALL_HISTORY_REDUCER: callHistoryReducer,
    AGENR_DETAILS: agentDetailsReducer,
    SUBSCRIPTIONS_DETAILS: SubscriptionReducer,
    ADMIN_DASHBOARD_DETAILS: adminDashboardReducer,
    VENDOR_LIST_REDUCER: vendorListReducer,
    ADDON_DETAILS: AddOnReducer,
    AR_PRODUCTS: ARProductReducer,
    VENDOR_PROFILE: VendorProfileReducer,
    RENEW_SUBSCRIPTION: RenewSubscriptionReducer,
    AGE_VERIFICATION: AgeVerificationReducer,
    VIRTUAL_BACKGROUND_IMAGE: VBImageReducer,
    VENDOR_ROOM: VendorRoomReducer,
    VENDOR_ROOM_SERVICES: vendorRoomServiceReducer
  });
