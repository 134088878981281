import { all, fork } from "redux-saga/effects";
import loginSagaListener from "./loginSaga";
import customerListSagaListener from "./CustomerListSaga";
import customerDeclineSagaListener from "./CutomerDeclineSaga";
import customerCallListListener from './CustomerCallListSaga';
import updateAppointmentListener from './UpdateAppointmentSaga'
import chatListListener from "./ChatListSaga";
import feedBackListener from './FeedBackSaga';
import callHistoryListener from './CallHistorySaga'
import screenShareListener from "./ScreenShareSaga";
import agentListListener from "./AgentDetailsSaga";
import adminSubscriptionListener from "./SubscriptionSaga";
import adminDashboardListener from "./AdminDashboardSaga";
import vendorListSagaListener from "./VendorListSaga";
import adminAddOnListener from "./AddOnSaga ";
import arProductListener from './ARProductSaga';
import vendorProfileListener from './VendorProfileSaga';
import renewSubscriptionListener from './RenewSubscriptionSaga'
import ageVerificationListener from "./AgeVerificationSaga";
import vBImageListener from "./VBImageSaga";
import vendorRoomListener from "./VendorRoomSaga";
import vendorRoomServiceListener from "./VendorRoomServiceSaga";
export default function* sagaWatchers() {
  yield all([
    fork(loginSagaListener),
    fork(customerListSagaListener),
    fork(customerDeclineSagaListener),
    fork(customerCallListListener),
    fork(updateAppointmentListener),
    fork(chatListListener),
    fork(feedBackListener),
    fork(callHistoryListener),
    fork(screenShareListener),
    fork(agentListListener),
    fork(adminSubscriptionListener),
    fork(adminDashboardListener),
    fork(vendorListSagaListener),
    fork(adminAddOnListener),
    fork(arProductListener),
    fork(vendorProfileListener),
    fork(renewSubscriptionListener),
    fork(ageVerificationListener),
    fork(vBImageListener),
    fork(vendorRoomListener),
    fork(vendorRoomServiceListener),
  ]);
}
